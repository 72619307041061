import LOGO from "../public/images/logo.png";
import PRO_LOGO from "../public/images/pro_logo.png";

// * * *  FEATURES * * *

import BACK from "../public/images/back.png";
import ERROR from "../public/images/error.png";
import SUCCESS from "../public/images/success.png";
import UPGRADE from "../public/images/features/upgrade.png";
import ERROR_IMG from "../public/images/err_platform.png";
import LEFT_GRADIENT from "../public/images/gradient-left.png";
import CHECK from "../public/images/check.png";

import SUPPORT from "../public/images/features/support.png";
import TRANSLATE from "../public/images/features/translate.png";
import REBRENDING from "../public/images/features/rebrending.png";
import NOTIFICATION from "../public/images/features/notification.png";
import BILLING_HISTORY from "../public/images/features/billing-history.png";
import PLATFORMS_STATISTICS from "../public/images/platforms-statistics.png";
import APPLICATION_UPDATE from "../public/images/features/application-update.png";
import MENU_CONFIGURATION from "../public/images/features/menu-configuration.png";

import TELEGRAM from "../public/images/telegram.png";
import JOIN_COMMUNITY from "../public/images/add-group.png";
import MESSAGE from "../public/images/message.png";
import NEWS from "../public/images/news.png";
import NEWS_LINE from "../public/images/news-line.png";
import NEWS_LINE_LIGHT from "../public/images/news-line_light.png";

import FEATURE_SLIDE1 from "../public/images/features/slide1.jpg";
import FEATURE_SLIDE2 from "../public/images/features/slide2.jpg";
import FEATURE_SLIDE3 from "../public/images/features/slide3.jpg";
import FEATURE_SLIDE4 from "../public/images/features/slide4.jpg";

// Download APK
import APPLE from "../public/images/download-app/apple.png";
import APPLE_DARK from "../public/images/download-app/apple-dark.png";
import ROKU from "../public/images/download-app/roku.png";
import ANDROID_MOBILE from "../public/images/download-app/google-play.png";
import ANDROID_TV from "../public/images/download-app/android.png";
import ANDROID_TV_APK from "../public/images/download-app/androidTVAPK.png";
import ANDROID_MOBILE_APK from "../public/images/download-app/androidMobileAPK.png";
import LG from "../public/images/download-app/lg.png";
import SAMSUNG_STORE from "../public/images/download-app/galaxy.png";
import DOWNLOADAPP_LIGHT from "../public/images/download-app/download-light.png";
import DOWNLOADAPP_DARK from "../public/images/download-app/download-dark.png";

// become partner
import PARTNER from "../public/images/become-partner/partner.png";
import BRANDED_PARTNER from "../public/images/become-partner/branded.png";
import CREDITS from "../public/images/become-partner/credits.png";
import PREMIUM_PARTNER from "../public/images/become-partner/premium.png";
import PRICES_PARTNER from "../public/images/become-partner/prices.png";
import MIGRATION_PARTNER from "../public/images/become-partner/migration.png";
import PRICE_SUBTRACT from "../public/images/price-subtract.svg";
import PRICE_SUBTRACT_DARK from "../public/images/price-subtract_dark.svg";

// about us
import ABOUTUS_1 from "../public/images/about/about_1.png";
import ABOUTUS_2 from "../public/images/about/about_2.png";
import ABOUTUS_3 from "../public/images/about/about_3.png";
import ABOUT_SUBTRACT from "../public/images/about/about-subtract.svg";
import ABOUTUS_1_DARK from "../public/images/about/about_1_dark.png";
import ABOUTUS_2_DARK from "../public/images/about/about_2_dark.png";
import ABOUTUS_3_DARK from "../public/images/about/about_3_dark.png";
import ABOUT_SUBTRACT_DARK from "../public/images/about/about-subtract_dark.svg";

import HOME_BG from "../public/images/home-dark.jpg";
import HOME_BG_LIGHT from "../public/images/home-light.jpg";
import HOME_LIGHT from "../public/images/home-img-light.png";
import HOME_DARK from "../public/images/home-img-dark.png";

import RESELLER_PANEL from "../public/images/reseller-panel.png";
import RESELLER_PANEL_LIGHT from "../public/images/reseller-panel-light.png";

import BRANDING from "../public/images/branding.png";
import BRANDING_LIGHT from "../public/images/branding-light.png";

import SERVER_DNS from "../public/images/dns-server.png";
import SERVER_DNS_LIGHT from "../public/images/dns-server-light.png";

import BG_BRANDED_APP from "../public/images/bg-branded-app.jpg";
import BG_BRANDED_APP_LIGHT from "../public/images/bg-branded-app-light.jpg";

import LIGHT from "../public/images/light.png";
import DARK from "../public/images/dark.png";

//flags
import EN from "../public/flags/en.png";
import PT from "../public/flags/pt.png";
import SQ from "../public/flags/sq.png";

import SW from "../public/flags/sw.png";
import AM from "../public/flags/am.png";
import OM from "../public/flags/om.png";
import IG from "../public/flags/ig.png";
import SO from "../public/flags/so.png";
import HA from "../public/flags/ha.png";
import MN from "../public/flags/mn.png";
import FU from "../public/flags/fu.png";
import YO from "../public/flags/yo.png";

//playlist
import PLAYLIST_BG from "../public/images/playlist-bg.jpg";
//purchase
import BITCOIN from "../public/images/bitcoin.svg";
import PAY_CARD from "../public/images/pay-card.svg";

import DONE_DUOTONE from "../public/images/done-duotone.svg";

const IMAGES = {
  // a

  SW,
  AM,
  OM,
  IG,
  SO,
  HA,
  MN,
  FU,
  YO,
  APPLICATION_UPDATE,
  ABOUTUS_1,
  ABOUTUS_2,
  ABOUTUS_3,
  ABOUT_SUBTRACT,
  ABOUTUS_1_DARK,
  ABOUTUS_2_DARK,
  ABOUTUS_3_DARK,
  ABOUT_SUBTRACT_DARK,
  ANDROID_MOBILE,
  ANDROID_TV,
  ANDROID_TV_APK,

  ANDROID_MOBILE_APK,
  APPLE,
  APPLE_DARK,
  // b
  BACK,
  BILLING_HISTORY,
  BRANDED_PARTNER,
  BRANDING,
  BRANDING_LIGHT,
  BG_BRANDED_APP,
  BG_BRANDED_APP_LIGHT,
  BITCOIN,
  // c
  CREDITS,
  CHECK,
  // d
  DARK,
  DOWNLOADAPP_LIGHT,
  DOWNLOADAPP_DARK,
  // e
  ERROR,
  ERROR_IMG,
  EN,
  // s
  SQ,
  SAMSUNG_STORE,
  // f
  FEATURE_SLIDE1,
  FEATURE_SLIDE2,
  FEATURE_SLIDE3,
  FEATURE_SLIDE4,
  // g
  // h
  HOME_BG,
  HOME_BG_LIGHT,
  HOME_LIGHT,
  HOME_DARK,
  // i
  ROKU,
  // j
  JOIN_COMMUNITY,
  // k
  // l
  LOGO,
  LEFT_GRADIENT,
  LIGHT,
  LG,
  // m
  MENU_CONFIGURATION,
  MESSAGE,
  MIGRATION_PARTNER,
  // n
  NEWS,
  NEWS_LINE,
  NEWS_LINE_LIGHT,
  NOTIFICATION,
  // o
  // p
  PRO_LOGO,
  PLATFORMS_STATISTICS,
  PARTNER,
  PREMIUM_PARTNER,
  PRICES_PARTNER,
  PT,
  PLAYLIST_BG,
  PAY_CARD,
  PRICE_SUBTRACT,
  PRICE_SUBTRACT_DARK,
  // q
  // r
  REBRENDING,
  RESELLER_PANEL,
  RESELLER_PANEL_LIGHT,
  // t
  TELEGRAM,
  TRANSLATE,
  DONE_DUOTONE,
  // u
  UPGRADE,
  // v
  // w
  // x
  // y
  // z
  SUCCESS,
  SUPPORT,
  SERVER_DNS,
  SERVER_DNS_LIGHT,
};

export default IMAGES;
